#root {
  height: 100vh;
  overflow: hidden;
  background-color: var(--main-color);
}

legend {
  user-select: none;
}

:root {
  --main-color: rgb(248, 249, 250);
  --object-bar-background: #38b4b4;
  --side-bar-background: #38b4b4;
}

[data-attributes="dark"] {
  --main-color: rgb(33, 37, 41);
  --object-bar-background: #38b4b4;
  --side-bar-background: #38b4b4;
}

.hidden {
  display: none;
}

.portal-background {
  background-image: url("/src/assets/portal.svg");
  background-position: center;
  background-size: cover;
}

.sideBar {
  background-color: var(--bs-gray-dark);
  user-select: none;
  overflow: hidden;
  position: relative;
  display: flex;
  /* top: 0px;
  left: 0px; */
  /* padding-top: 56px; */
  /* height: 100%; */
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.groupBar {
  /* background-color: var(--bs-gray-dark); */
  user-select: none;
  overflow: hidden;
  position: relative;
  display: flex;
  /* top: 0px;
  left: 0px; */
  /* padding-top: 56px; */
  /* height: 100%; */
  /* box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; */
}

.groupBar-handle {
  position: absolute;
  z-index: 100;
  width: 4px;
  top: 0;
  bottom: 0;
  right: 0;
  transition: 0.3s;
  background: var(--bs-gray-600);
}

.sideBar-handle {
  position: absolute;
  z-index: 100;
  width: 4px;
  top: 0;
  bottom: 0;
  right: 0;
  transition: 0.3s;
  background: var(--bs-gray-600);
}

.groupBar-handle-closed {
  position: absolute;
  z-index: 100;
  width: 4px;
  top: 0;
  bottom: 0;
  right: 0;
  transition: 0.3s;
  background: var(--bs-gray-600);
}

.sideBar-handle-closed {
  position: absolute;
  z-index: 100;
  width: 4px;
  top: 0;
  bottom: 0;
  right: 0;
  transition: 0.3s;
  background: var(--bs-gray-600);
}

.groupBar-handle:hover,
.groupBar-handle:active {
  background: var(--bs-gray-500);
  cursor: col-resize;
}

.group-fields {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.group-by-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  padding: 0 1rem 0 1rem;
  height: 3rem;
  min-height: 3rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  font-size: 1rem;
}
.group-by-item.selected {
  background-color: var(--bs-gray-300) !important;
  border-bottom: 1px solid rgb(64, 180, 252);
}

.group-by-item:hover {
  background-color: var(--bs-gray-400) !important;
}

.sideBar-handle:hover,
.sideBar-handle:active {
  background: var(--bs-gray-500);
  cursor: col-resize;
}

.gadget-handle {
  position: absolute;
  z-index: 100;
  height: 4px;
  width: 100%;
  bottom: 0;
  right: 0;
  transition: 0.3s;
  background: var(--bs-gray-600);
}

.gadget-handle:hover,
.gadget-handle:active {
  background: var(--bs-gray-500);
  cursor: row-resize;
}

.s1-web-menu {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  max-height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
}

.s1-web-menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  padding-left: 1rem;
  height: 3rem;
  min-height: 3rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  color: white;
  font-size: 1rem;
}
.s1-web-menu-item > div > svg {
  font-size: 1rem;
}

.s1-web-menu-item.current-folder {
  background-color: var(--bs-secondary);
  /* padding: 1rem 1rem; */
  height: 3rem;
}

.s1-rel-job-menu-title {
  margin: 0 1rem 0 1rem;
  border-bottom: 1px solid black;
  font-size: 1.2rem;
  font-weight: 500;
}

.s1-rel-job-menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.8rem;
  padding-left: 1rem;
  height: 2rem;
  min-height: 2rem;
}

.tab-list {
  display: flex;
  width: 100%;
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity));
  height: 36px;
  background-color: var(--bs-gray-600);
  position: relative;
  overflow-x: hidden;
}

.tab-list-wrapper {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
}

.tab-list-controls {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
}

.tab-list-control-left,
.tab-list-control-right {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: var(--bs-light);
  color: var(--bs-dark);
}

.tab-list-control-left {
  margin: 0.25rem 0.25rem 0 0;
}

.tab-list-control-right {
  margin: 0.25rem 0.25rem 0 0.25rem;
}

.tab-list-context-menu {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.175);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 10px 0 10px 0;
  position: fixed;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.3);
  z-index: 2001;
}

.tab-list-context-menu-action {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  margin: 0.15rem 0 0.1rem 0;
  padding: 0.25rem 1rem;
  user-select: none;
}

.tab-list-context-menu-action.active {
  background-color: #e9ecef;
  border: 0;
}

.tab-list-context-menu-action:hover {
  background-color: #e9ecef;
}

.tab-window {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 0.25rem; */
  margin-top: 0.25rem;
  cursor: pointer;
  background-color: var(--bs-gray-dark);
  color: var(--bs-gray-light);
  user-select: none;
  max-width: 300px;
}

/* .tab-window.dashboard{
  background-color: var(--bs-gray-600);
  color: var(--bs-gray-light);
} */

.tab-window.active {
  background-color: var(--bs-light);
  color: var(--bs-dark);
}

.tab-panel {
  display: none;
  flex: 1;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.tab-panel.dashboard {
  background-image: url("/src/assets/portal.svg");
  background-position: center;
  background-size: cover;
}

.tab-panel.active {
  display: flex;
}

.object-top-bar {
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity));
  gap: 1.5rem;
  /* overflow: hidden; */
  margin-top: 0.5rem;
  /* height: calc(3rem - 8px); */
  background-color: rgb(64, 180, 252);
}

.object-top-bar.form {
  justify-content: space-between;
  gap: 0;
}

.inputGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0.4rem 0;
}

.inputGroup.has-error {
  border-bottom: 1px solid red;
}

.inputGroup label {
  /* width: 120px; */
  /* min-width: 120px;
  max-width: 120px; */
  /* margin-right: 5px; */
  /* height: 30px;
  display: flex;
  /* flex-wrap: nowrap; */
  /*align-items: center; */
  border-bottom: 1px dotted #b9b9b9;
  height: 30px;
  min-width: 140px;
  max-width: 140px;
}

.input-with-buttons {
  margin-left: 0.25rem;
  display: flex;
  width: 100%;
  /* gap: 0.5rem; */
  border-bottom: 1px solid #b9b9b9;
  height: 30px;
}

.text-area-input-group {
  display: flex;
  flex-direction: column;
  margin: 0.4rem 0;
}

.text-area-input-group label {
  height: 30px;
  border-bottom: 1px dotted #b9b9b9;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.text-area-input-group textarea {
  width: 100%;
  border: none;
  outline: none;
}

.textarea-with-buttons {
  display: flex;
  width: 100%;
  gap: 0.5rem;
  border: 1px solid #b9b9b9;
}

.underline-focus {
  border-bottom: 1px solid rgb(64, 180, 252);
}

.whole-focus {
  border: 1px solid rgb(64, 180, 252);
}

.whole-required {
  border: 1px solid var(--bs-danger) !important;
}

.inputGroup input {
  width: 100%;
  border: none;
  outline: none;
}

.input-required {
  border-bottom: 1px solid var(--bs-danger) !important;
}

div.required {
  border-bottom: 2px solid var(--bs-danger) !important;
}

input {
  background-color: var(--bs-light);
}

input.disabled {
  color: rgba(0, 0, 0, 0.5);
  cursor: default;
}

svg.disabled {
  color: rgba(0, 0, 0, 0.5) !important;
  cursor: default;
}

textarea {
  background-color: var(--bs-light);
}

.loadModal {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2000;
}

.picker {
  z-index: 100000 !important;
}

.input-picker-load {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 2000;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  background-color: var(--bs-backdrop-bg);
  opacity: var(--bs-backdrop-opacity);
}

.tab-loader {
  height: 1rem;
  width: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-loader > div {
  width: 4px;
  height: 4px;
  margin: 1px;
  border-radius: 50%;
  background-color: var(--bs-dark);
  opacity: 1;
  animation: tab-loader 0.6s infinite alternate;
}

@keyframes tab-loader {
  to {
    opacity: 0.1;
    transform: translateY(-2px);
  }
}

.tab-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.tab-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

.screen-loader {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2000;
}

.bouncing-loader {
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bouncing-loader > div {
  width: 16px;
  height: 16px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: var(--bs-dark);
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

.nav-tabs {
  border-bottom: none;
}

.nav-link {
  border: none;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 8px 16px;
  margin-right: -1px;
  background-color: #f1f1f1;
  color: #444;
}

.nav-link.active {
  background-color: #fff;
  color: #000;
  border: none;
  border-bottom-color: #fff;
  z-index: 1;
}

.tab-pane {
  padding: 16px;
  border: 1px solid #ddd;
  border-top: none;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #fff;
}

.s1-object-dialog {
  padding: 1rem;
  height: 100%;
  overflow-y: auto;
}

.s1-object-browser {
  overflow-y: hidden;
  height: 100%;
}

.s1-object-form {
  padding: 1rem;
  height: 100%;
  overflow: auto;
  scrollbar-width: thin;
}

.s1-portal {
  padding: 1rem;
  height: 100%;
  overflow: auto;
  scrollbar-width: thin;
}

.s1cont {
  display: flex;
  flex: 1 1 100%;
  flex-direction: row;
  gap: 0.5rem;
}

.input-picker-div {
  position: fixed;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.3);
  background-color: var(--bs-light);
  display: flex;
  flex-direction: column;
  z-index: 100;
  min-width: 400px;
}

.input-picker-div.custom {
  min-width: 0;
}

.input-picker {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.input-picker-table-row {
  user-select: none;
  cursor: pointer;
  border-width: 1px;
  border-left: none;
  border-right: none;
  border-color: var(--bs-gray-500);
  min-height: 40px;
}

.input-picker-table-row.hovered {
  background-color: #3b465a !important;
  color: var(--bs-light);
}

.input-picker-table-row-selected {
  background-color: #dee3ec;
  border-bottom: 1px solid rgb(64, 180, 252);
}

.input-picker-table-row:nth-child(even) {
  background-color: #fff;
}

.input-picker-table-row:nth-child(even).input-picker-table-row-selected {
  background-color: #dee3ec;
  border-bottom: 1px solid rgb(64, 180, 252);
}

.input-picker-table-row:hover {
  background-color: #3b465a !important;
  color: var(--bs-light);
}

.browser-table {
  display: flex;
  flex-direction: column;
  border-spacing: 0;
}

.browser-table-thead {
  background-color: var(--bs-gray-dark);
  overflow-y: auto;
  overflow-x: hidden;
}

.browser-table-tbody {
  overflow: auto;
  flex: 1;
  scrollbar-width: thin;
  /* height: 250px; */
}

.browser-table-tfoot {
  background-color: var(--bs-gray-dark);
  overflow-y: auto;
  overflow-x: hidden;
}

/* .browser-table-th,.browser-table-td {
  margin: 0;
  padding: 0.5rem;
  position: relative;
} */

.browser-table-resizer {
  display: inline-block;
  background: var(--bs-gray-600);
  width: 4px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  touch-action: none;
}

.browser-table-resizer:hover {
  background: var(--bs-gray-500);
}

.browser-table-resizer.isResizing {
  background: var(--bs-gray-500);
}

.browser-table-thead > .browser-table-tr > .browser-table-th {
  background-color: var(--bs-gray-dark);
  color: var(--bs-light);
  padding: 0.5rem;
  user-select: none;
  padding: 0.5rem;
  position: relative;
}

.browser-table-thead > .browser-table-tr > .browser-table-th:hover {
  background-color: var(--bs-gray-600) !important;
}

.browser-table-tfoot > .browser-table-tr > .browser-table-td {
  background-color: var(--bs-gray-dark);
  color: var(--bs-light);
  user-select: none;
  padding: 0.5rem;
  position: relative;
}

.browser-table-tfoot > .browser-table-tr > .browser-table-td.hoverable:hover {
  background-color: var(--bs-gray-600) !important;
}

.browser-table-tbody > .browser-table-tr:nth-child(even) > div {
  background-color: #fff;
}

.browser-table-tbody > .browser-table-tr {
  background-color: #f6f6f6;
  min-height: 41px;
}

.browser-table-tbody > .browser-table-tr.row-selected > div {
  background-color: var(--bs-gray-300);
  border-bottom: 1px solid rgb(64, 180, 252);
}

.browser-table-tbody > .browser-table-tr {
  user-select: none;
}

.browser-table-tbody > .browser-table-tr > .browser-table-td {
  padding: 0.5rem;
  position: relative;
}

.browser-table-tbody > .browser-table-tr:hover > div {
  background-color: var(--bs-gray-300);
}

input[type="date"]::-webkit-datetime-edit-text,
input[type="date"]::-webkit-datetime-edit-month-field,
input[type="date"]::-webkit-datetime-edit-day-field,
input[type="date"]::-webkit-datetime-edit-year-field {
  -webkit-appearance: textfield;
  opacity: 0%;
}

input[type="date"].date-input-has-value::-webkit-datetime-edit-text,
input[type="date"].date-input-has-value::-webkit-datetime-edit-month-field,
input[type="date"].date-input-has-value::-webkit-datetime-edit-day-field,
input[type="date"].date-input-has-value::-webkit-datetime-edit-year-field {
  opacity: 100%;
}

input[type="datetime-local"]::-webkit-datetime-edit-text,
input[type="datetime-local"]::-webkit-datetime-edit-month-field,
input[type="datetime-local"]::-webkit-datetime-edit-day-field,
input[type="datetime-local"]::-webkit-datetime-edit-hour-field,
input[type="datetime-local"]::-webkit-datetime-edit-minute-field,
input[type="datetime-local"]::-webkit-datetime-edit-ampm-field,
input[type="datetime-local"]::-webkit-datetime-edit-day-field,
input[type="datetime-local"]::-webkit-datetime-edit-year-field {
  opacity: 0%;
}

input[type="datetime-local"].date-input-has-value::-webkit-datetime-edit-text,
input[type="datetime-local"].date-input-has-value::-webkit-datetime-edit-month-field,
input[type="datetime-local"].date-input-has-value::-webkit-datetime-edit-day-field,
input[type="datetime-local"].date-input-has-value::-webkit-datetime-edit-hour-field,
input[type="datetime-local"].date-input-has-value::-webkit-datetime-edit-minute-field,
input[type="datetime-local"].date-input-has-value::-webkit-datetime-edit-ampm-field,
input[type="datetime-local"].date-input-has-value::-webkit-datetime-edit-day-field,
input[type="datetime-local"].date-input-has-value::-webkit-datetime-edit-year-field {
  opacity: 100%;
}

input[type="time"]::-webkit-datetime-edit-text,
input[type="time"]::-webkit-datetime-edit-hour-field,
input[type="time"]::-webkit-datetime-edit-minute-field,
input[type="time"]::-webkit-datetime-edit-ampm-field {
  opacity: 0%;
}

input[type="time"].date-input-has-value::-webkit-datetime-edit-text,
input[type="time"].date-input-has-value::-webkit-datetime-edit-hour-field,
input[type="time"].date-input-has-value::-webkit-datetime-edit-minute-field,
input[type="time"].date-input-has-value::-webkit-datetime-edit-ampm-field {
  opacity: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  display: none;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.preview-open {
  width: 100vw;
  max-width: none;
  height: 100%;
  padding: 2% 15%;
  margin: 0;
}

.preview-open .modal-content {
  height: 100%;
}

.btn-close:focus {
  box-shadow: none !important;
}

.no-preview {
  cursor: row-resize;
  background-color: transparent;
}

.s1-web-menu::-webkit-scrollbar,
.group-fields::-webkit-scrollbar,
.s1-object-dialog::-webkit-scrollbar,
.softone-report::-webkit-scrollbar,
.input-picker::-webkit-scrollbar,
.input-picker-div::-webkit-scrollbar,
.s1-portal::-webkit-scrollbar,
.s1-object-form::-webkit-scrollbar,
.browser-table-tbody::-webkit-scrollbar,
.fc-scroller::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */
}

.s1-web-menu::-webkit-scrollbar-thumb,
.group-fields::-webkit-scrollbar-thumb,
.s1-object-dialog::-webkit-scrollbar-thumb,
.softone-report::-webkit-scrollbar-thumb,
.input-picker::-webkit-scrollbar-thumb,
.input-picker-div::-webkit-scrollbar-thumb,
.s1-portal::-webkit-scrollbar-thumb,
.s1-object-form::-webkit-scrollbar-thumb,
.browser-table-tbody::-webkit-scrollbar-thumb,
.fc-scroller::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the scrollbar thumb */
}

.s1-web-menu::-webkit-scrollbar-thumb:hover,
.group-fields::-webkit-scrollbar-thumb:hover,
.s1-object-dialog::-webkit-scrollbar-thumb:hover,
.softone-report::-webkit-scrollbar-thumb:hover,
.input-picker::-webkit-scrollbar-thumb:hover,
.input-picker-div::-webkit-scrollbar-thumb:hover,
.s1-portal::-webkit-scrollbar-thumb:hover,
.s1-object-form::-webkit-scrollbar-thumb:hover,
.browser-table-tbody::-webkit-scrollbar-thumb:hover,
.fc-scroller::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set the color of the scrollbar thumb on hover */
}

.s1-web-menu::-webkit-scrollbar-track,
.group-fields::-webkit-scrollbar-track,
.s1-object-dialog::-webkit-scrollbar-track,
.softone-report::-webkit-scrollbar-track,
.input-picker::-webkit-scrollbar-track,
.input-picker-div::-webkit-scrollbar-track,
.s1-portal::-webkit-scrollbar-track,
.s1-object-form::-webkit-scrollbar-track,
.browser-table-tbody::-webkit-scrollbar-track,
.fc-scroller::-webkit-scrollbar-track {
  background-color: transparent; /* Set the color of the scrollbar track */
}

.modal-content {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.above-all-backdrop {
  z-index: 8000;
}

.modal:has(.above-all-dialog) {
  z-index: 8001;
}

.calendar-selected-record {
  border: 1px solid red;
}
