.login-screen {
  background-color: var(--main-color);
  background-image: url("/src/assets/portal.svg");
  background-position: center;
  background-size: cover;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-screen > .card {
  width: 100%;
  max-width: 450px;
  padding: 1.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
