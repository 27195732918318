.custom-tooltip {
  position: fixed;
  top: 60px;
  left: 60px;
  transform: translateY(-50%);
  background-color: var(--bs-gray-dark);
  border: 2px solid var(--bs-gray-600);
  color: #fff;
  padding: 6px;
  border-radius: 4px;
  font-size: 14px;
  visibility: hidden;
  opacity: 1;
  z-index: 9000000000;
  transition: opacity 0.3s, visibility 0.3s;
}

.custom-tooltip-container:hover .custom-tooltip {
  visibility: visible;
  opacity: 1;
}
